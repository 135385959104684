:root { 
	/* Basic theme */
	--app-font-family: "Open Sans";
	--app-background: #fff;
	--app-header: #fff;
	--app-header-border: #ccc;
	--app-toolbar: #f4f4f4;
	--app-text-color: #222;
	--app-error-color: #ec0707;
	--main-color-accent: #007ad9;
	--main-color-pale: #4ba1e3;
	--main-color-light: #b8dbf6;
	--main-color-dark: #005597;
	--main-color-darker: #012e51;

	/* IBMi colors */
	--ibm-grn-color: #00d963;
	--ibm-wht-color: #a0a0a0;
	--ibm-red-color: #d90000;
	--ibm-trq-color: #00d9b3;
	--ibm-ylw-color: #e4df0c;
	--ibm-pnk-color: #f78eaf;
	--ibm-blu-color: var(--main-color-accent, #007ad9);

	/* Modals */
	--modal-background: var(--app-background, #fff);
	--modal-border: #c8c8c8;
	--modal-header-text: #fff;

	/* Navigation */
	--nav-background: #f5f5f5;
	--nav-background-item-color: var(--app-text-color, #222);
	--nav-background-item-hover: #dcdcdc;
	--nav-background-item-focus: #c6c6c6;

	/* Inputs */
	--input-background: #fff;
	--input-border: #a6a6a6;
	--input-background-disabled: #f3f3f3;

	/* Datagrid */
	--grid-selected-row: var(--main-color-light, #b8dbf6);
	--grid-filter-button: var(--main-color-accent, #007ad9);
	--grid-selector-accent: var(--main-color-accent, #007ad9);
	--grid-selector-hover: var(--main-color-dark, #005597);
	--grid-actions-background: #fff;
	--grid-background-odd: #f4f4f4;
	--grid-background-even: #fff;
	--grid-input: var(--input-background, #fff);
	--grid-border: #ddd;
	--grid-header: #f4f4f4;
	--grid-footer: #f4f4f4;

	/* Buttons */
	--button-accent: var(--main-color-accent, #007ad9);
	--button-hover: var(--main-color-dark, #005597);
	--button-focus: var(--main-color-darker, #012e51);

	--button-action: #fff;
	--button-action-border: transparent;
	--button-action-hover: #f5f7f7;
	--button-action-focus: #e8e8e8;
	--button-action-text: #767676;

	--button-fkey: #f4f4f4;
	--button-fkey-border: #ddd;
	--button-fkey-hover: #c8c8c8;
	--button-fkey-focus: #959595;
	--button-fkey-text: var(--app-text-color, #222);

	--button-continue-accent: var(--button-accent, #007ad9);
	--button-continue-hover: var(--button-hover, #005597);
	--button-continue-focus: var(--button-focus, #012e51);
}